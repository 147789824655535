<template>
  <figure class="user-avatar" v-if="user" :style="`--picture:url(${user.picture});--size:${size || '49px'}`" :type="type">
    <span uppercase v-if="!user.picture">{{ user.name && user.name[0] }}</span>
    <span class="media-pending" v-if="isPendingMedia" @click="viewPendingMedia">
      <iconic name="triangle_exclamation" />
    </span>
    <!-- <i class="flag" :style="`--flag:url(${user.country_flag})`"></i> -->
  </figure>
</template>

<script>
export default {
  props: ["user", "size", "type"],
  methods: {
    viewPendingMedia: function() {
      this.$store.state.user.profileReview = true;
      this.$emit("action");
    },
  },
  computed: {
    isPendingMedia: function() {
      if (!this.$profile?.user?.payload) {
        return false;
      }

      const isMe = this.isMyAvatar;
      const pendingPic = this.$profile.user.payload.picture;
      const pendingCov = this.$profile.user.payload.cover;

      if (isMe && pendingPic && pendingPic.adminStatus !== "accept") {
        return true;
      }

      if (isMe && pendingCov && pendingCov.adminStatus !== "accept") {
        return true;
      }
    },
    isMyAvatar: function() {
      return this.$profile && this.$profile.user.id === this.user.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  @include BgImage();
  position: relative;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  background-image: var(--picture);
  color: #fff;
  @include Flex(inherit, center, center);
  .media-pending {
    position: absolute;
    top: -$mpadding/2;
    right: -$mpadding/1.5;
    width: $mpadding * 2;
    height: $mpadding * 2;
    background: red;
    color: rgb(255, 255, 255);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid #fff;
    cursor: pointer;
    overflow: hidden;
  }
  &[type="story"] {
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: solid $primary_color 2px;
      border-style: dashed;
    }
    &:before {
      border-style: solid;
      border-color: #fff;
    }
  }
}
</style>
